import * as R from 'ramda';
import * as messagesReducer from '../reducers/messages';

export const item = (state, id) => messagesReducer.findById(state, id);

export const list = (state) => R.map(messagesReducer.findById(state), state.admin.messages.ids);

export const getMetaUsers = (state) => {
  return state.admin.messages.meta?.related?.users || [];
};
