import React, { useCallback, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as R from 'ramda';

import * as AlertService from '@common/services/alert';
import Container from '@common/components/container';
import Overview from '@common/components/overview';
import { AsyncTable } from '@common/components/table';
import Permission from '@common/components/permission';
import { Button } from '@common/components/button';
import Bar from '@common/components/bar';
import SearchBar from '@common/components/search-bar';
import { EPermissions, EPlanPackageConfig } from '@common/definitions';

import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { deleteChannel, fetchChannels, updateChannelOrder } from '@modules/admin/actions';

import ChannelRow from '../../components/channel-row';
import ChannelActions from '../../components/channel-actions';

import * as organisationSelector from '../../../organisation/selectors/organisation';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as userSelector from '../../../core/selectors/logged-user';

const OrganisationChannels = ({ channels }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState(null);
  const [order, setOrder] = useState(R.pluck('id', channels));

  const {
    isAvailable: canCreateOrganisationGroups, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ORGANISATION_GROUPS, channels.length);

  const channelColumns = useMemo(() => [{
    size: 35,
  }, {
    label: t('organisation:communication_channel_name'),
    className: 'Table__Cell__Title',
  }, {
    label: t('organisation:communication_channel_created'),
  }], [t]);

  const items = useMemo(() => {
    return R
      .sortBy((channel) => R.findIndex(R.equals(channel.id), order), channels)
      .filter((channel) => (search
        ? channel.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        : true
      ));
  }, [channels, order, search]);

  const onChangeOrder = useCallback((sourceId, targetId) => {
    const index = R.indexOf(targetId, order);
    setOrder(R.insert(index, sourceId, R.reject(R.equals(sourceId), order)));
  }, [order, setOrder]);

  const onSaveOrder = useCallback(async () => {
    try {
      await dispatch(updateChannelOrder(order));
      AlertService.success(t('learning:saved_order'));
    } catch (response) {
      AlertService.forStatus(response.status_code, {
        warning: t('learning:warning_saving_order'),
        error: t('learning:error_saving_order'),
      });
    }
  }, [dispatch, t, updateChannelOrder, order]);

  return (
    <Container name="Channels">
      <Container.Content>
        <Bar>
          <h2>
            <Trans i18nKey="organisation:content_channels" />
            <a
              href={t('organisation:content_channels_learn_more')}
              target="_blank"
              rel="noreferrer"
            >
              <Trans i18nKey="core:learn_more" />
            </a>
          </h2>
          <SearchBar
            placeholder={t('organisation:communication_search')}
            onSearch={setSearch}
          />
          <Permission name={EPermissions.ORGANISATION_CHANNELS_CREATE}>
            <Button
              type="primary"
              size="large"
              iconRight="add"
              onClick={() => {
                if (canCreateOrganisationGroups) {
                  history.push('/admin/content/channels/create');
                } else {
                  showUpgradeModal();
                }
              }}
            >
              <Trans i18nKey="organisation:communication_channel_create" />
            </Button>
          </Permission>
        </Bar>
        <Overview>
          <Overview.Content>
            <AsyncTable
              columns={channelColumns}
              items={items}
              data={{
                onFetch: () => dispatch(fetchChannels()),
                onPostFetch: ({ data }) => setOrder(R.pluck('id', data)),
              }}
              onChangeOrder={onChangeOrder}
              onDrop={onSaveOrder}
              renderRow={ChannelRow}
              rowProps={(item) => ({
                onOpen: () => history.push(`/admin/content/channels/${item.id}/edit`),
                onDelete: () => dispatch(deleteChannel(item.id)),
              })}
              ActionComponent={ChannelActions}
              placeholder={(
                <Overview.Placeholder>
                  {search ? t('organisation:communication_no_channels_found') : t('organisation:communication_no_channels')}
                </Overview.Placeholder>
              )}
            />
          </Overview.Content>
        </Overview>
      </Container.Content>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  organisation: organisationSelector.selected(state),
  loggedUser: userSelector.selected(state),
  channels: organisationSelector.channels(state),
  pagination: {
    channels: state.organisation.channels.pagination,
  },
});

export default (connect(mapStateToProps)(pageWrapper(EEventNames.VISITED_ORGANISATION_GROUPS_PAGE)(OrganisationChannels)));
