import React from 'react';
import { reduxForm, Field } from 'redux-form';
import * as R from 'ramda';
import { Trans, withTranslation } from 'react-i18next';

import ProfileImage from '@common/components/profile-image';
import * as alert from '@common/services/alert';
import Api from '@common/services/api';
import { predicatesToPayload } from '@common/utils/predicates';
import { MultiInput } from '@common/components/predicates-filter';
import Collapsible from '@common/components/collapsible';
import { Button } from '@common/components/button';
import { AudienceInput } from '@common/components/form/audience';
import TextInput from '@common/components/form/inputs/text';
import Group from '@common/components/form/group';
import Label from '@common/components/form/label';
import Row from '@common/components/form/row';
import FileCropInput from '@common/components/form/inputs/file-crop';
import CheckboxInput from '@common/components/form/inputs/checkbox';
import CheckboxReverse from '@common/components/form/inputs/checkbox-reverse';

import { EPredicateFields, ESearchPredicateTypes } from '@common/definitions';
import TimelineHeader from '../../../social/components/timeline-header';
import { createChannel, updateChannel } from '../../actions';
import ModeratorRow from './moderator-row';
import './styles.scss';

const getFileName = (file) => {
  if (!file || file.path === null) return null;

  const parts = (file.path || file).split(file.default ? 'attachments/' : '/');

  return parts[parts.length - 1];
};

const defaultLogos = [
  'https://assets.oneteam.io/globals/attachments/default/channels/logo1.png',
  'https://assets.oneteam.io/globals/attachments/default/channels/logo2.png',
  'https://assets.oneteam.io/globals/attachments/default/channels/logo3.png',
  'https://assets.oneteam.io/globals/attachments/default/channels/logo4.png',
  'https://assets.oneteam.io/globals/attachments/default/channels/logo5.png',
  'https://assets.oneteam.io/globals/attachments/default/channels/logo6.png',
  'https://assets.oneteam.io/globals/attachments/default/channels/logo7.png',
  'https://assets.oneteam.io/globals/attachments/default/channels/logo8.png',
];

const DefaultLogoComponent = withTranslation()(({ input: { value, onChange }, t }) => (
  <div className="Selector">
    {defaultLogos.map((path) => (
      <div
        key={path}
        onClick={() => onChange({ path, default: true })}
        className={`Selector__Item${value.path === path ? ' Selector__Item--selected' : ''}`}
      >
        <img
          src={path}
          alt={t('organisation:forms_channel_logo_alt')}
          style={{ width: '100%' }}
        />
      </div>
    ))}
  </div>
));

class ChannelForm extends React.Component {
  static defaultProps = {
    formValues: {
      theme: {},
      settings: {},
      filters: {},
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      searchResult: null,
    };

    this.setGroupReference = (ref) => (this.group = ref);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdateStep = () => this.setState({ update: new Date() }); // eslint-disable-line react/no-unused-state
    this.handleSearchOrganisationUsers = (search) => {
      if (!search) return [];

      const query = Api.utils.toQuery({ q: search, limit: 5 });

      return Api.get(`/v2/organisations/${props.organisation.id}/users?${query}`)
        .then((result) => result.data);
    };
    this.handleGoToStep = (index) => this.group && this.group.handleGoToStep(index);
    this.getInjectedReduxState = this.getInjectedReduxState.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { channel, initialize } = this.props;

    if (channel) {
      initialize({
        id: channel.id,
        name: channel.name,
        theme: {
          logo: {
            path: channel.theme.logo_url || null,
            default: !!(channel.theme.logo_url && channel.theme.logo_url.indexOf('/default') > -1),
          },
          header_image: { path: channel.theme.header_image_url || null },
        },
        settings: {
          can_all_post: !!channel.settings.can_all_post,
          can_comment: !!channel.settings.can_comment,
          moderators: channel.settings.moderators.map(R.pick(['id', 'full_name', 'profile_img'])),
        },
        audience: {
          predicate_type: channel.predicate_type,
          predicates: channel.predicates.map((predicate, i) => R.assoc('id', i, predicate)),
        },
      });
    }
  }

  static props;
  group;

  async handleSubmit(values, dispatch) {
    const { channel, organisation } = this.props;

    const payload = {
      name: values.name,
      organisation_id: organisation.id,
      theme: {
        logo_url: getFileName(values.theme.logo),
        header_image_url: getFileName(values.theme.header_image),
      },
      settings: {
        can_comment: !!values.settings.can_comment,
        can_all_post: !!values.settings.can_all_post,
        moderators: values.settings.moderators.map(R.prop('id')),
      },
      predicate_type: values.audience.predicate_type,
      predicates: predicatesToPayload(values.audience.predicates),
    };

    try {
      if (channel) {
        await dispatch(updateChannel(channel.id, payload));
        alert.success(this.props.t('organisation:forms_channel_edit_success'));
      } else {
        await dispatch(createChannel(payload));
        alert.success(this.props.t('organisation:forms_channel_create_success', { channelName: values.name }));
      }

      this.props.onGoBack();
    } catch (response) {
      alert.forStatus(response.status_code, {
        warning: values.id
          ? this.props.t('organisation:forms_channel_edit_warning')
          : this.props.t('organisation:forms_channel_create_warning'),
        error: values.id
          ? this.props.t('organisation:forms_channel_edit_error')
          : this.props.t('organisation:forms_channel_create_error'),
      });
    }
  }

  // workaround to fix PD-8191, provide to a fake redux state the users
  // fetched locally
  getInjectedReduxState() {
    const { getState, injectablePredicateUsers } = this.props;
    const state = getState();

    const injectedState = {
      ...state,
      users: {
        ...state?.users,
        items: {
          ...state?.users?.items,
          ...Object.assign(
            {},
            ...injectablePredicateUsers.map((u) => ({ [u.id]: u }))
          )
        }
      }
    };
    return injectedState;
  }

  render() {
    const { searchResult } = this.state;
    const {
      channel, organisation, formValues, admins, submitting, handleSubmit, t,
      injectablePredicateUsers
    } = this.props;

    let uncompletedSteps = [];
    if (this.group) {
      uncompletedSteps = R.pipe(
        R.dropLast(1),
        R.reject(R.prop('completed')),
      )(this.group.handleGetStatus());
    }

    const orgAdmins = admins.filter((admin) => admin.roles.some((role) => role.is_global));

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)} className="Form">
        <Collapsible.Group ref={this.setGroupReference} onStatusChange={this.handleUpdateStep}>
          <Collapsible title={t('organisation:forms_channel_general')} completed={!!formValues.name}>
            <Collapsible.Column flex={1}>
              <Row>
                <Group>
                  <Label forInput="name" text={t('organisation:forms_channel_name')} maxLength={30} />
                  <TextInput name="name" placeholder={t('organisation:forms_channel_name_placeholder')} maxLength={30} />
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label forInput="theme.header_image" text={t('organisation:forms_channel_photo')} />
                  <FileCropInput name="theme.header_image" aspectRatio={2.3399014778} processFile>
                    <Button>
                      {formValues.theme.header_image
                        ? t('organisation:forms_channel_file_change')
                        : t('organisation:forms_channel_file')}
                    </Button>
                  </FileCropInput>
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label forInput="theme.logo" text="Logo" />
                  <Trans
                    i18nKey="organisation:forms_channel_logo_description"
                    components={[<FileCropInput name="theme.logo" processFile>a</FileCropInput>]}
                  />
                  <Field
                    name="theme.logo"
                    component={DefaultLogoComponent}
                  />
                </Group>
              </Row>
            </Collapsible.Column>
            <Collapsible.Column flex={1}>
              <Row>
                <Group>
                  <Label forInput="name" text={t('organisation:forms_channel_name_example')} />
                  <TimelineHeader
                    organisation={organisation}
                    channel={{
                      type: 'preview',
                      parent: {
                        name: formValues.name || t('organisation:forms_channel_name_empty'),
                        theme: {
                          header_image_url: formValues.theme.header_image ? formValues.theme.header_image.path : null,
                          logo_url: formValues.theme.logo ? formValues.theme.logo.path : null,
                        },
                        users_count: 1,
                      },
                    }}
                  />
                </Group>
              </Row>
            </Collapsible.Column>
          </Collapsible>

          <Collapsible title={t('organisation:forms_channel_settings_target_audience')} completed={channel ? true : undefined}>
            <Collapsible.Column>
              <Row>
                <Group>
                  <AudienceInput
                    name="audience"
                    enabledFilters={[
                      EPredicateFields.USER,
                      EPredicateFields.LANGUAGE,
                      EPredicateFields.NETWORK,
                      EPredicateFields.FUNCTION,
                    ]}
                    {
                      // if we are updating a channel then we are providing
                      // users to inject
                      ...(
                        injectablePredicateUsers?.length ?
                          { getState: this.getInjectedReduxState } :
                          null
                      )
                    }
                  />
                </Group>
              </Row>
            </Collapsible.Column>
          </Collapsible>

          <Collapsible title={t('organisation:forms_channel_settings_title')} completed={channel ? true : undefined}>
            <Collapsible.Column flex={1}>
              <h3><Trans i18nKey="organisation:forms_channel_settings_moderators" /></h3>
              <Field
                name="settings.moderators"
                valueKey="id"
                labelKey="full_name"
                searchPlaceholder={t('organisation:forms_channel_settings_moderators_search_placeholder')}
                defaultSelected={orgAdmins}
                // defaultText={t('organisation:forms_channel_settings_moderators_text')}
                getOptions={this.handleSearchOrganisationUsers}
                renderRow={ModeratorRow}
                component={({ input, ...props }) => (
                  <MultiInput
                    {...props}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              />
            </Collapsible.Column>
            <Collapsible.Column flex={1}>
              <h3><Trans i18nKey="organisation:forms_channel_settings_disable_comments" /></h3>
              <Row>
                <Group>
                  <CheckboxReverse
                    name="settings.can_comment"
                    label={t('organisation:forms_channel_settings_can_comment')}
                    description={t('organisation:forms_channel_settings_can_comment_description')}
                  />
                </Group>
              </Row>
              <h3><Trans i18nKey="organisation:forms_channel_settings_post_messages" /></h3>
              <Row>
                <Group>
                  <CheckboxInput
                    name="settings.can_all_post"
                    label={t('organisation:forms_channel_settings_can_all_post')}
                  />
                </Group>
              </Row>
              <Row>
                <Group>
                  <CheckboxReverse
                    name="settings.can_all_post"
                    label={t('organisation:forms_channel_settings_can_some_post')}
                  />
                </Group>
              </Row>
            </Collapsible.Column>
          </Collapsible>

          <Collapsible title={t('organisation:forms_channel_settings_check')} onOpen={() => this.setState({})} completed={false}>
            <Collapsible.Column flex={0.4} />
            <Collapsible.Column flex={1} padding="large">
              {uncompletedSteps.length > 0 && (
                <>
                  <center>
                    <h2 style={{ marginBottom: 24 }}>
                      <Trans i18nKey="organisation:forms_channel_settings_check_description" />
                    </h2>
                  </center>
                  {uncompletedSteps.map((step) => (
                    <Collapsible.Button {...step} onClick={() => this.handleGoToStep(step.index)} />
                  ))}
                </>
              )}
              {uncompletedSteps.length === 0 && (
                <center>
                  {searchResult && (
                    <div className="ChannelForm__Recipients">
                      {R.take(8, searchResult.data).map((user) => (
                        <ProfileImage key={user.id} size={35} user={user} />
                      ))}
                    </div>
                  )}
                  <h2 style={{ marginBottom: 16 }}>{formValues.name}</h2>
                  <Button isLoading={submitting} size="large" type="primary" buttonType="submit">
                    {channel ? t('organisation:forms_channel_edit_submit') : t('organisation:forms_channel_create_submit')}
                  </Button>
                </center>
              )}
            </Collapsible.Column>
            <Collapsible.Column flex={0.4} />
          </Collapsible>
        </Collapsible.Group>
      </form>
    );
  }
}

export default withTranslation()(reduxForm({
  form: 'channel',
  initialValues: {
    name: '',
    theme: {
      logo: null,
      header_image: null,
    },
    settings: {
      can_all_post: true,
      can_comment: true,
      moderators: [],
    },
    audience: {
      predicate_type: ESearchPredicateTypes.MATCH_ANY,
      predicates: [],
    },
  },
  // validate,
})(ChannelForm));
