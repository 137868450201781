import * as React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { memoize } from 'lodash';
import { reduxForm, change } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';

import ProfileImage from '@common/components/profile-image';
import Attachments from '@common/components/form/attachments';
import { AudienceInput } from '@common/components/form/audience';
import FileInput from '@common/components/form/inputs/file';
import FileInputModal from '@common/components/form/inputs/file-modal';
import Poll from '@common/components/form/inputs/poll';
import CheckboxReverse from '@common/components/form/inputs/checkbox-reverse';
import DateTimePicker from '@common/components/form/inputs/datetime';
import MarkupEditor from '@common/components/form/inputs/markup';
import { Row, Group } from '@common/components/form';
import * as alert from '@common/services/alert';
import ConfirmButton from '@common/components/confirm-button';
import { Button } from '@common/components/button';
import Icon from '@common/components/icon';
import Alert from '@common/components/alert';
import Modal from '@common/components/modal';
import Collapsible from '@common/components/collapsible';
import { Messages } from '@common/components/form/message';

import { ALL_ACCEPT } from '@common/components/form/inputs/file/utils';
import * as fileUtil from '@common/utils/file';
import * as predicateUtil from '@common/utils/predicates';
import { formatNetworks } from '@modules/core/reducers/networks';
import { createMessage, updateMessage } from '../../actions';
import { ETimelineTypes } from '../../../social/definitions';

require('../../../flexchange/forms/styles.scss');

const maxAmountOfFiles = 10;

const formatAudienceInjectedReduxState = memoize((state, networks) => {
  const audienceState = {
    ...state,
    networks: {
      ...state?.networks,
      items: {
        ...formatNetworks(networks)
      }
    },
    organisation: {
      ...state?.organisation,
      networks: {
        ...state?.organisation?.networks,
        items: networks.map((n) => n.id)
      }
    }
  };
  return audienceState;
});

class PostOrganisationMessageForm extends React.Component {

  constructor(props) {
    super();

    this.state = {
      showPoll: false,
      fileErrors: [],
      loading: false,
    };

    this.setGroupReference = (ref) => (this.group = ref);
    this.handleToggleImportant = () => props.change('is_important', !this.props.formValues.is_important);
    this.handleUpdateStep = () => this.setState({ update: new Date() }); // eslint-disable-line react/no-unused-state
    this.handleGoToStep = (index) => this.group && this.group.handleGoToStep(index);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAudienceInjectedReduxState = this.getAudienceInjectedReduxState.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { message: organisationMessage, initialValues } = this.props;
    const { message } = organisationMessage || {};

    if (organisationMessage) {
      this.setState({
        showPoll: !organisationMessage.processed ? !!organisationMessage.message.poll : false,
      });

      this.props.initialize({
        ...initialValues,
        text: message.text,
        can_comment: message.can_comment,
        audience: {
          predicate_type: (
            organisationMessage.audience && organisationMessage.audience.predicate_type
          ) || predicateUtil.ESearchPredicateTypes.MATCH_ANY,
          predicates: (
            organisationMessage.audience &&
            organisationMessage.audience.predicates.map((predicate, i) => R.assoc('id', i, predicate))
          ) || [],
        },
        publish_at: moment(organisationMessage.publish_at),
        attachments: [
          ...organisationMessage.message.attachments,
          ...organisationMessage.message.videos,
          ...organisationMessage.message.files,
        ],
        poll: !organisationMessage.processed
          ? {
            question: message.poll ? message.poll.question : '',
            options: message.poll ? message.poll.options.map((option) => ({ id: option.id, value: option.text })) : [],
            is_multiple_choice: message.poll ? !!message.poll.is_multiple_choice : false,
            show_votes: message.poll ? message.poll.show_votes : true,
            expires_at: message.poll?.expires_at ? moment(message.poll.expires_at) : false,
          }
          : initialValues.poll,
      });
    }
  }

  componentDidMount() {
    if (this.props.isEdit) {
      const steps = this.group.handleGetStatus();
      // we assume the content editor is always the second to last step
      this.handleGoToStep(steps.length - 2);
    }
  }

  static props;

  async handleSubmit(values, dispatch) {
    const { history, message, t } = this.props;
    this.setState({ loading: true });

    const data = {
      text: values.text,
      files: R.pluck('id', values.attachments),
      link_preview: R.isEmpty(values.attachments) ? values.link_preview : undefined,
      publish_at: values.publish_at ? values.publish_at.toISOString() : null,
      can_comment: values.can_comment,
      is_important: values.is_important,
    };

    if (!message || (message && !message.is_legacy)) {
      data.audience = {
        predicate_type: values.audience.predicate_type,
        predicates: predicateUtil.predicatesToPayload(values.audience.predicates),
      };
    }

    if (this.state.showPoll && values.poll) {
      data.poll_question = values.poll.question;
      data.poll_options = R.pipe(R.pluck('value'), R.reject(R.isEmpty))(values.poll.options);
      data.poll_is_multiple_choice = values.poll.is_multiple_choice;
      data.poll_show_votes = values.poll.show_votes;
      if (values.poll.expires_at) {
        data.poll_expires_at = values.poll.expires_at;
      }
    }

    try {
      if (message) {
        await dispatch(updateMessage(message.id, data));
        alert.success(t('organisation:forms_message_edit_success'));
      } else {
        await dispatch(createMessage(data));
        const success = data.send_at ?
          t('organisation:forms_message_scheduled_success') :
          t('organisation:forms_message_create_success');
        alert.success(success);
      }

      history.push(message ? `/admin/content/messages/${message.id}` : '/admin/content/messages');
    } catch (response) {
      const warning = message ? t('organisation:forms_message_edit_warning') : t('organisation:forms_message_create_warning');
      const error = message ? t('organisation:forms_message_edit_error') : t('organisation:forms_message_create_error');
      alert.forStatus(response.status_code, { warning, error });
    } finally {
      this.setState({ loading: false });
    }
  }

  getAudienceInjectedReduxState(state) {
    const { networks } = this.props;
    return formatAudienceInjectedReduxState(state, networks);
  }

  render() {
    const { showPoll, searchResult, fileErrors } = this.state;
    const {
      dispatch,
      organisation,
      message,
      formValues,
      handleSubmit,
      history,
      t,
    } = this.props;
    const { attachments = [], text, poll = { options: [] } } = (formValues || {});

    const validOptions = R.pipe(R.pluck('value'), R.reject(R.isEmpty))(poll.options);
    const disabled = !(
      (
        text !== ''
        || attachments.length !== 0
        || (showPoll && poll.question !== '' && validOptions.length > 0)
      )
      && !fileUtil.isProcessingAttachments(attachments)
    );

    const submit = handleSubmit(this.handleSubmit);

    let uncompletedSteps = [];
    if (this.group) {
      uncompletedSteps = R.pipe(
        R.dropLast(1),
        R.reject(R.prop('completed')),
      )(this.group.handleGetStatus());
    }

    const invisible = message && (message.is_legacy || message.processed);

    return (
      <form className="Form">
        <Collapsible.Group ref={this.setGroupReference} onStatusChange={this.handleUpdateStep}>
          {
            invisible ?
              null :
              (
                <Collapsible
                  title={t('organisation:forms_message_target_audience')}
                  completed={message ? true : undefined}
                >
                  <Collapsible.Column>
                    <Row>
                      <Group>
                        <AudienceInput
                          name="audience"
                          getSearchResults={(result) => this.setState({ searchResult: result })}
                          getInjectedState={this.getAudienceInjectedReduxState}
                          enabledFilters={[
                            predicateUtil.EPredicateFields.USER,
                            predicateUtil.EPredicateFields.LANGUAGE,
                            predicateUtil.EPredicateFields.NETWORK,
                            predicateUtil.EPredicateFields.FUNCTION,
                          ]}
                        />
                      </Group>
                    </Row>
                  </Collapsible.Column>
                </Collapsible>
              )
          }
          <Collapsible title={t('organisation:forms_message_settings_title')} completed={message ? true : undefined}>
            <Collapsible.Column flex={1}>
              <h3><Trans i18nKey="organisation:forms_message_settings_disable_comments" /></h3>
              <Row>
                <Group>
                  <CheckboxReverse
                    name="can_comment"
                    label={t('organisation:forms_message_settings_can_comment')}
                    description={t('organisation:forms_message_settings_can_comment_description')}
                  />
                </Group>
              </Row>
            </Collapsible.Column>
          </Collapsible>
          <Collapsible title={t('organisation:forms_message_content')} completed={!disabled} open>
            <Collapsible.Column>
              <Row>
                <Group>
                  <MarkupEditor
                    rows={10}
                    name="text"
                    placeholder={t('organisation:forms_message_text_placeholder')}
                    onLinkPreviewChanged={(linkPreview) => this.props.change('link_preview', linkPreview)}
                    useLinkPreview={R.isEmpty(formValues.attachments)}
                    target={{
                      type: ETimelineTypes.ORGANISATION,
                      id: organisation.id,
                    }}
                  />
                </Group>
              </Row>
              <Row>
                <Group>
                  {fileErrors.length > 0 && <Messages errors={fileErrors} />}
                </Group>
              </Row>
              <Attachments
                name="attachments"
                addInput={(
                  <FileInput
                    multiple={maxAmountOfFiles}
                    name="attachments"
                    accept="image/*, video/*"
                  >
                    <Icon type="add" />
                  </FileInput>
                )}
              />
              {(!message || (message && !message.processed)) && this.state.showPoll && (
                <Row>
                  <Group>
                    <Poll name="poll" closePoll={() => this.setState({ showPoll: false })} />
                  </Group>
                </Row>
              )}
              {formValues.is_important && (
                <Alert type="info">
                  <Trans i18nKey="social:post_message_important_explanation" />
                </Alert>
              )}
              <Row className="CreateMessage__Attachments">
                {!message && (
                  <Button
                    className="PostMessage__Button--poll"
                    icon="poll__filled"
                    size="large"
                    onClick={() => this.setState({ showPoll: !showPoll })}
                    active={showPoll}
                  >
                    <Trans i18nKey="social:post_message_poll" />
                  </Button>
                )}
                <FileInputModal
                  unsplash
                  multiple={maxAmountOfFiles}
                  name="attachments"
                  accept="image/*, video/*"
                  onError={(errors) => this.setState({ fileErrors: errors })}
                  maxFileSize={500}
                >
                  <Button
                    className="PostMessage__Button--attachment"
                    icon="image__filled"
                    size="large"
                  >
                    <Trans i18nKey="social:post_message_attachment_photo_video" />
                  </Button>
                </FileInputModal>
                <FileInput
                  multiple={maxAmountOfFiles}
                  name="attachments"
                  onError={(errors) => this.setState({ fileErrors: errors })}
                  accept={ALL_ACCEPT}
                >
                  <Button
                    className="PostMessage__Button--document"
                    icon="document__filled"
                    size="large"
                  >
                    <Trans i18nKey="social:post_message_attachment_document" />
                  </Button>
                </FileInput>
                <Button
                  className="PostMessage__Button--important"
                  icon="star__filled"
                  size="large"
                  onClick={this.handleToggleImportant}
                  active={formValues.is_important}
                >
                  <Trans i18nKey="social:post_message_important" />
                </Button>
              </Row>
            </Collapsible.Column>
          </Collapsible>
          <Collapsible
            title={message
              ? t('organisation:forms_message_confirm_edit_title')
              : t('organisation:forms_message_send')}
            onOpen={() => this.setState({})}
            completed={false}
          >
            <Collapsible.Column flex={0.4} />
            <Collapsible.Column>
              {uncompletedSteps.length > 0 && (
                <>
                  <center>
                    <h2 style={{ marginBottom: 24 }}>
                      <Trans i18nKey="organisation:forms_message_settings_check_description" />
                    </h2>
                  </center>
                  {uncompletedSteps.map((step) => (
                    <Collapsible.Button key={step.title} {...step} onClick={() => this.handleGoToStep(step.index)} />
                  ))}
                </>
              )}
              {uncompletedSteps.length === 0 && (
                <>
                  {searchResult && (
                    <>
                      <center>
                        <div className="ChannelForm__Recipients">
                          {searchResult.data.slice(0, 8).map((user) => (
                            <ProfileImage key={user.id} size={35} user={user} />
                          ))}
                        </div>
                      </center>
                      <hr />
                    </>
                  )}
                  <div className="CreateMessage__Actions">
                    {(!message || (message && !message.processed && !message.publish_at)) && (
                      <>
                        <ConfirmButton
                          title={t('organisation:forms_message_confirm_title')}
                          description={(
                            t('organisation:forms_message_create_confirm_description', {
                              countString: searchResult?.meta.pagination.total_count,
                            })
                          )}
                          confirmText={t('organisation:forms_message_create_button')}
                          onConfirm={async () => {
                            await dispatch(change('create-organisation-message', 'send_at', null));

                            submit();
                          }}
                        >
                          <Button type="primary" disabled={disabled} isLoading={this.state.loading}>
                            <Trans i18nKey="organisation:forms_message_create_button" />
                          </Button>
                        </ConfirmButton>
                        <span><Trans i18nKey="organisation:forms_message_or" /></span>
                      </>
                    )}
                    {(!message || (message && !message.processed)) && (
                      <Modal
                        list
                        size="small"
                        className="modal-datetimepicker modal--color-grey Form"
                        content={(
                          <DateTimePicker name="publish_at" />
                        )}
                        hideDefaultClose={this.state.loading}
                        footer={(
                          <ConfirmButton
                            title={t('organisation:forms_message_schedule_confirm_title')}
                            description={(
                              t('organisation:forms_message_schedule_confirm_description', {
                                countString: searchResult?.meta.pagination.total_count,
                              })
                            )}
                            confirmText={t('organisation:forms_message_schedule_button')}
                            onConfirm={submit}
                          >
                            <Button type="primary" isLoading={this.state.loading}>
                              <Trans i18nKey="organisation:forms_message_schedule_button" />
                            </Button>
                          </ConfirmButton>
                        )}
                      >
                        <Button type="primary" disabled={disabled || this.state.loading}>
                          <Trans i18nKey="organisation:forms_message_schedule_button" />
                        </Button>
                      </Modal>
                    )}
                    {message && message.processed && (
                      <ConfirmButton
                        title={t('organisation:forms_message_confirm_edit_title')}
                        description={t('organisation:forms_message_confirm_edit_description')}
                        confirmText={t('organisation:forms_message_edit_button')}
                        onConfirm={async () => {
                          await dispatch(change('create-organisation-message', 'send_at', null));

                          submit();
                        }}
                      >
                        <Button type="primary" disabled={disabled} isLoading={this.state.loading}>
                          <Trans i18nKey="organisation:forms_message_edit_button" />
                        </Button>
                      </ConfirmButton>
                    )}
                    <Button type="default" onClick={() => history.goBack()} disabled={this.state.loading}>
                      <Trans i18nKey="organisation:forms_message_cancel_button" />
                    </Button>
                  </div>
                </>
              )}
            </Collapsible.Column>
            <Collapsible.Column flex={0.4} />
          </Collapsible>
        </Collapsible.Group>
      </form>
    );
  }
}

export default withTranslation()(reduxForm({
  form: 'create-organisation-message',
  initialValues: {
    text: '',
    attachments: [],
    link_preview: undefined,
    can_comment: true,
    poll: {
      question: '',
      options: [],
      is_multiple_choice: false,
      show_votes: true,
      expires_at: false,
    },
    send_at: null,
    is_important: false,
    audience: {
      predicate_type: predicateUtil.ESearchPredicateTypes.MATCH_ANY,
      predicates: [],
    },
  },
})(PostOrganisationMessageForm));
